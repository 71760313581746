.link {
  display: block;
  text-decoration: none;

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

.cardBox{
  padding: 16px;
  background-color: var(--BG-Base-Dark-Tertiary);
  border-radius: 16px;
  position: relative;

  .cardBoxContents{
    display: grid;
    row-gap: 8px;

    .tournamentName{
      display: flex;
      align-items: center;
      column-gap: 4px;
      font-size: 14px;
      color: var(--TEXT-Base-Light-Tertiary)
    }
    .contentsHolderLogo{
      width: 24px;
      height: auto;
    }

    .teamInfo{
      display: flex;
      align-items: center;
      column-gap: 4px;
      min-width: 0px;
    }

    .emblem{
      display: block;
      flex: none;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
    }

    .teamCategory{
      min-width: 0px;
      white-space: nowrap;
    }

    .teamName{
      font-size: 16px;
      color: var(--TEXT-Base-Light-Primary);
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 19.2px;
    }
    .homeAndAway{
      font-size: 12px;
      color: var(--TEXT-Base-Light-Tertiary);
      line-height: 14.88px;
    }

    .additionalInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      .tournamentDate{
        font-size: 14px;
        color: var(--TEXT-Base-Light-Tertiary);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .label {
        position: relative;
        flex-shrink: 0;
        padding: 8px 12px;
        border-radius: 12px;
        background: var(--Gradient-Transparent);
        color: var(--TEXT-Base-Light-Primary);
        font-size: 12px;
        display: flex;
        align-items: center;
        line-height: 75%;  /* ラベルの高さがFigmaと同じ25pxになるよう調整 */
        
        &::before {
          content: "";
          position: absolute;
          inset: 0;
          padding: 1px;
          border-radius: inherit;
          background: var(--Gradient);
          mask:
            linear-gradient(to right, #000 0 0, #000 0 0) content-box,
            linear-gradient(to right, #000 0 0, #000 0 0);
          mask-composite: exclude;
        }
      }
    }
  }
}
