.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  background-color: var(--BG-Base-Dark-Quaternary);
  border-radius: 4px;
  color: var(--TEXT-Base-Light-Primary);
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  a {
    font-size: 15px;
    color: var(--TEXT-Link-Primary);
  }
}

.item {
  margin-top: 12px;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  background: url(/img/icon/arrow_right.svg) no-repeat center right;
  background-size: 16px auto;
  text-decoration: none;
  color: inherit;
}

.date {
  color: var(--TEXT-Base-Light-Tertiary);

  span {
    margin-left: 4px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--STROKE-Tag-Secondary);
    font-size: 9px;
    font-weight: bold;
    color: var(--GRAY-Light20);
  }
}

.name {
  margin-top: 4px;
  overflow-wrap: anywhere;
  margin-right: 4px;
}

.highlightLink {
  text-decoration: none;
}

.highlight {
  padding: 12px;
  margin: 12px 0;
  background-color: var(--BG-Base-Dark-Tertiary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.highlightImage {
  width: 48px;
}

.highlightText {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.45;
  color: var(--TEXT-Base-Colored-Primary);
  white-space: pre-line;
}
